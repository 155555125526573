<template>
  <detail-form-modal v-slot="slotProps"
    resourceName="Label"
    auditResourceName="ORGANIZATIONLABEL"
    :crudService="crudService"
    :itemId="itemId"
    dialog-class="label-detail-form-modal-dialog"
    @close="$emit('close')"
    @saved="$emit('saved', $event)"
    @apply-grid-transaction="$emit('apply-grid-transaction', $event)"
  >
    <label-form v-bind="slotProps" :lockLabelType="labelType" />
  </detail-form-modal>
</template>
<script>
import DetailFormModal from '@/components/DetailFormModal.vue'
import LabelForm from './LabelForm.vue'
import crudService from './services/LabelService'

export default {
  name: 'LabelFormModal',
  components: {
    DetailFormModal,
    LabelForm
  },
  props: {
    itemId: Number,
    labelType: String
  },
  emits: ['apply-grid-transaction', 'close', 'saved'],
  data () {
    return {
      crudService
    }
  }
}
</script>
<style lang="scss">
.label-detail-form-modal-dialog {
  max-width: 500px !important;
}
</style>
