import restClient from './clients/rest'
import _ from 'lodash'

const moduleName = 'orgService'
const resource = 'manage/orgs'

async function retrieve (orgId) {
  try {
    const response = await restClient.get(`${resource}/${orgId}`, { orgMode: 'none' })
    return response.data ? response.data.results[0] : null
  } catch (error) {
    console.warn('Error retrieving org', error)
    throw error
  }
}

async function list (params) {
  try {
    const response = await restClient.get(resource, { orgMode: 'none', params })
    return _.get(response, 'data.results', [])
  } catch (error) {
    console.warn(`Error listing orgs`, error)
    throw error
  }
}

async function retrieveLastAccessedOrg () {
  try {
    const response = await restClient.get(`${resource}/last-accessed`, { orgMode: 'none' })
    // if data is null, then there is no last accessed org
    return response.data ? response.data.results[0] : null
  } catch (error) {
    console.warn('Error retrieving last accessed org', error)
    throw error
  }
}

async function acceptOrg (orgId) {
  try {
    const response = await restClient.post(`${resource}/${orgId}/accept`, null, { orgMode: 'none' })
    return response.data
  } catch (error) {
    console.warn('Error accepting org', error)
    throw error
  }
}

export function registerOrgServiceWithStore (store) {

  function handleOrgResult (org) {
    return new Promise((resolve, reject) => {
      if (!org) {
        reject(new Error('no org retrieved'))
      } else if (!org.orgUserMeetsAuthRuleRequirements) {
        store.dispatch(`${moduleName}/failedOrgAuthRequirement`, org)
        resolve()
      } else if (!org.orgUserAccepted) {
        store.dispatch(`${moduleName}/orgRequiresAcceptance`, org)
        resolve()
      } else {
        // load session for this org
        resolve(org)
      }
    })
  }

  function trySpecifiedOrg (orgId) {
    return retrieve(orgId).then(handleOrgResult)
  }

  function tryLastAccessedOrg () {
    return retrieveLastAccessedOrg().then(handleOrgResult)
  }

  function tryOrgList () {
    return list()
      .then(orgs => {
        store.commit(`${moduleName}/orgListRetrieved`, orgs)

        if (orgs && orgs.length === 1) {
          return handleOrgResult(orgs[0])
        } else {
          // user intervention required
          // TODO: not working => need to display either sign-up or org list
          return null
        }
      })
  }

  store.registerModule(moduleName, {
    namespaced: true,
    state () {
      return {
        selectedOrg: null,
        allOrgs: [],
        orgListLoaded: false
      }
    },
    getters: {
      selectedOrgHasIssue: state => {
        const selectedOrg = state.selectedOrg
        return !!selectedOrg && (!selectedOrg.orgUserAccepted || !selectedOrg.orgUserMeetsAuthRuleRequirements)
      },
      orgListHasIssue: state => !state.selectedOrg && state.orgListLoaded && state.allOrgs.length !== 1,
      userInterventionNeeded: (state, getters) => getters.selectedOrgHasIssue || getters.orgListHasIssue
    },
    actions: {
      bootstrapOrg (context, orgId) {
        let initialRetrieve

        if (orgId) {
          initialRetrieve = trySpecifiedOrg(orgId)
            .catch(error => {
              console.warn(`Failed to bootstrap specified ${orgId} due to error ${error}. Will fall back to last accessed org.`)
              return tryLastAccessedOrg()
            })
        } else {
          initialRetrieve = tryLastAccessedOrg()
        }

        initialRetrieve
          .catch(() => tryOrgList())
          .catch(() => {
            context.commit('fatalErrorDidOccur', { fatalErrorMsg: 'Failed to find organization' }, { root: true })
            return null
          })
          .then(org => {
            if (!org) return // was already handled
            context.commit('orgSelected', org)
            context.dispatch('initSession', org.id, { root: true })
            console.log(`Bootstrapped org ${org.name} (${org.id})`)
          })
      },
      failedOrgAuthRequirement (context, org) {
        if (org) context.commit('orgSelected', org)
        else context.commit('failedOrgAuthRequirement')
      },
      orgRequiresAcceptance (context, org) {
        context.commit('orgSelected', org)
      },
      async acceptOrg (context, orgId) {
        try {
          await acceptOrg(orgId)
          context.commit('acceptOrg', orgId)
        } catch (error) {
          context.commit('fatalErrorDidOccur', { fatalErrorMsg: 'Failed to accept organization' }, { root: true })
        }
      },
      async loadList (context, { forceRefresh, params }) {
        if (context.state.orgListLoaded && !forceRefresh) return // successful promise
        const items = await list(params)
        context.commit('orgListRetrieved', items)
      }
    },
    mutations: {
      orgSelected (state, org) {
        state.selectedOrg = org
      },
      orgListRetrieved (state, orgs) {
        state.allOrgs = orgs
        state.orgListLoaded = true
      },
      failedOrgAuthRequirement (state) {
        state.selectedOrg.orgUserMeetsAuthRuleRequirements = false
      },
      acceptOrg (state, orgId) {
        const org = state.selectedOrg
        if (!org || org.id !== orgId) return
        org.orgUserAccepted = true
        this.dispatch('initSession', orgId, { root: true })
      }
    }
  })
}
