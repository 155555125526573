<template>
<face-failure-content v-bind="$attrs"
  @approve="approve"
  @reject="reject"
  :approveDisabled="!employee"
  >
  <template #employeeQuestion>
    <span class="question">Who is this?</span>
    <worker-multiselect
      v-model="employeeId"
      :contextId="clockLogId"
      :allowedForOrgUnit="clockOrgUnit"
      @fullValueChange="employee = $event"
    />
  </template>
</face-failure-content>
</template>
<script>
import FaceFailureContent from './FaceFailureContent.vue'
import WorkerMultiselect from '@/components/WorkerMultiselect.vue'

import { mapGetters } from 'vuex'

export default {
  inheritAttrs: false,
  components: {
    FaceFailureContent,
    WorkerMultiselect
  },
  data () {
    return {
      employeeId: null,
      employee: null
    }
  },
  props: {
    clockLogId: Number,
    clockOrgUnit: Number
  },
  emits: ['approve', 'reject'],
  computed: {
    ...mapGetters('formatPreferences', ['formatName'])
  },
  watch: {
    clockLogId () {
      // whenever we load a new clock log, reset employee selection
      this.employeeId = null
    }
  },
  methods: {
    approve () {
      this.$emit('approve', this.employee)
    },
    reject () {
      this.$emit('reject')
    }
  }
}
</script>
<style lang="scss" scoped>

.question {
  margin-bottom: 5px;
}

</style>
