<template>
  <router-link :to="url" custom #default="{ isActive }">
    <li :class="['nav-item', 'nav-dropdown', sidebarMinimized ? {} : { open: isActive }]" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><font-awesome-icon :icon="icon" /> {{name}}</div>
      <ul class="nav-dropdown-items">
        <slot></slot>
      </ul>
    </li>
  </router-link>
</template>

<script>
export default {
  name: 'SidebarNavDropdown',
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    sidebarMinimized () {
      // This computed field is not reactive.
      // We really just added this property in order to control the 'open' class above to try to fix a regression in vue-router when upgrading to Vue 3.
      // In Vue 2 (i.e., vue-router 3), when the 'open' class was explicitly toggled in code, navigated to a link under the dropdown, and then navigated away,
      // then the dropdown would be closed automatically by vue-router, seemingly due to the linkActiveClass set on the router options. This behavior no longer
      // occurs in Vue 3 (i.e., vue-router 4). A customer complained about it, so we are trying to fix the regression.
      // The exact use-case is that the sidebar is not minimized, and user expand Manage. Then user clicks on Timecards. Later use clicks on Dashboard.
      // It is expected that the Manage dropdown should close.
      return document.body.classList.contains('sidebar-minimized')
    }
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      const parentElement = e.target.parentElement
      if (parentElement) parentElement.classList.toggle('open')
    },
    // Need to set menu list max height each time it's opened in order for
    // scroll bar to work.
    mouseEnter (event) {
      if (!document.body.classList.contains('sidebar-minimized')) return

      const el = event.target
      const menu = el.querySelector('ul.nav-dropdown-items')
      menu.style.maxHeight=`calc(100vh - ${menu.getBoundingClientRect().y}px)`
    },
    mouseLeave (event) {
      const el = event.target
      const menu = el.querySelector('ul.nav-dropdown-items')
      menu.style.maxHeight = null
    }
  }
}
</script>
