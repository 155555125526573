import { computed, inject, onMounted, ref, watch } from "vue"
import { matchedRouteKey, useRouter } from 'vue-router'
import { useActiveRoute } from "./useActiveRoute"
import { hasAccessToRoute } from '@/router/RouterAccessGuard'
import store from '@/store'

export function useRouterViewMeta () {
  const { activeRoute, activeRouteFullPath, route } = useActiveRoute()
  const router = useRouter()
  const matchedRoute = inject(matchedRouteKey)

  const includeInKeepAlive = ref([])
  const icon = computed(() => activeRoute.value?.meta?.icon || null)
  const label = computed(() => activeRoute.value?.meta?.label || null)
  const parent = computed(() =>
    (activeRoute.value?.matched?.length ?? 0) > 2
      // parent is 2nd to last element
      ? (activeRoute.value?.matched.at?.(-2) ?? null)
      // do not consider first element as a parent in navigation
      : null
  )
  const children = computed(() => {
      // this.$route.matched contains list of RouteRecords, but it doesn't contain
      // the current route's children. So we need to look that up in a separate
      // object.
      const configuredRoutes = router.options.routes
      let routeCursor = { children: configuredRoutes }

      if (activeRoute.value) {
        for (const mr of activeRoute.value.matched) {
          routeCursor = (routeCursor?.children || []).find(
            r => r.name === mr.name)
          if (!routeCursor || mr === matchedRoute?.value) {
            // unexpected
            break
          }
        }
      }

      return ((routeCursor?.children) || [])
        .filter(child => child?.meta?.nav)
        .filter(child => hasAccessToRoute(child, store))
  })

  function checkKeepAlive () {
    // We only want to cache certain components, such as the dashboard.
    // We configure keepAlive in route configuration for each components.
    // Note that the component must have a name in order to keep alive.
    // Note that due to Vue's use of component name for enabling cache,
    // if any instance of that component is cached, then all instances will be,
    // such as RouterPassThrough.
    // Note that the component that mixes in this class must declare a
    // <keep-alive> directive, and bind include to includeInKeepAlive.

    const routeMatchedIndex = (route.matched || [])
      .findIndex(r => r === parent?.value)
    if (routeMatchedIndex > -1 && route.matched.length > routeMatchedIndex + 1) {
      const childRouteInView = route.matched[routeMatchedIndex + 1]
      if (childRouteInView.meta.keepAlive) {
        const childComponent = childRouteInView?.components?.default
        if (!includeInKeepAlive.value.includes(childComponent?.name)) {
          includeInKeepAlive.value.push(childComponent?.name)
          // const selfComponent = this.$route.matched[routeMatchedIndex].components.default
          // console.debug(`Including ${childComponent.name} in keep alive for ${selfComponent.name}`)
        }
      }
    }
  }

  // TODO: Fix watcher not triggering.
  watch(() => activeRouteFullPath.value, () => {
    checkKeepAlive()
  })

  onMounted(() => {
    checkKeepAlive()
  })

  return {
    activeRoute,
    activeRouteFullPath,
    children,
    icon,
    includeInKeepAlive,
    label,
    matchedRoute,
    parent
  }
}
